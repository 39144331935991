export function SelectedWriting() {
  return (
    <div className="SelectedWritingSection">
      <p className="Publication">
        <span className="Label">Criticism</span>
        <span className="PublicationName">
          "It Is Easier to Imagine the End of Opera than the End of
          Capitalism"&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Forthcoming in&nbsp;
          <i>Los Angeles Review of Books</i>
        </span>
      </p>
      <p className="Publication">
        <span className="Label">Criticism</span>
        <span className="PublicationName">
          "Like Water for Chocolate"&nbsp;at American Ballet
          Theatre&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Forthcoming in&nbsp;
          <i>The Drift</i>
        </span>
      </p>
      <p className="Publication">
        <span className="Label">Interview</span>
        <span className="PublicationName">
          "A Conversation with Garth
          Greenwell"&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Forthcoming in&nbsp;
          <i>The Adroit Journal</i>
        </span>
      </p>
    </div>
  );
}
