import memoriaImage from "./memoria.jpg";
export function Contact() {
  return (
    <div class="wrap">
      <div class="memoriaImage">
        <img src={memoriaImage} />
      </div>
      <br />
      <p className="contactText">contact [at] nicholaszliu [dot] com</p>
    </div>
  );
}
