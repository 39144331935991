import "./App.css";
import { SelectedWriting } from "./SelectedWriting";
import { Contact } from "./Contact";
import { useState } from "react";
import { Analytics } from "@vercel/analytics/react";

function App() {
  const [navBarSelection, setNavBarSelection] = useState("SelectedWriting");
  function handleClick(e) {
    e.preventDefault();
    if (e.target.className === "Contact") {
      setNavBarSelection("Contact");
    }
    if (
      e.target.className === "SelectedWriting" ||
      e.target.className === "name"
    ) {
      setNavBarSelection("SelectedWriting");
    }
  }
  return (
    <div className="App">
      <header className="App-header"></header>
      <div className="navBar" onClick={handleClick}>
        <div className="name" href="localhost:3000">
          Nicholas Z. Liu
        </div>
        <div className="menu">
          <a className="SelectedWriting" href="#selectedWriting">
            {navBarSelection === "SelectedWriting" ? (
              <u>Selected Writing</u>
            ) : (
              <>Selected Writing</>
            )}
          </a>
          <a className="Contact" href="#contact">
            {navBarSelection === "Contact" ? <u>Contact</u> : <>Contact</>}
          </a>
        </div>
      </div>
      {navBarSelection === "SelectedWriting" ? SelectedWriting() : Contact()}
      <Analytics />
    </div>
  );
}

export default App;
